/* eslint-disable */
import { createRoot } from 'react-dom/client';
import { reduce, entries } from 'lodash';
import App from './App';
import { isLocalePath, setDefaultLocale } from './package/utils/locale';

import './package/styles/global.css';
import './styles/animations.css';
import './styles/tailwind.css';
import { DEFAULT_THEME } from '@welovedevs/ui';

import { createTheme } from '@mui/material/styles';
import MuiStylesProvider from '@mui/styles/StylesProvider';
import { create } from 'jss';
import { JssProvider } from 'react-jss';
import jssDefaultPreset from 'jss-preset-default';

if (!isLocalePath()) setDefaultLocale();

const { palette } = DEFAULT_THEME;

const muiInstance = create(jssDefaultPreset());
const jssinstance = create(jssDefaultPreset());
muiInstance.setup({ insertionPoint: 'mui-insertion-point' });
jssinstance.setup({ insertionPoint: 'jss-insertion-point' });

export const theme = createTheme({
    spacing: 8,
    palette: reduce(
        entries(palette),
        (acc, [name, values]) => {
            const copy = acc;
            copy[name].main = values[500];
            return copy;
        },
        palette
    )
});

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

root.render(
    <MuiStylesProvider jss={muiInstance}>
        <JssProvider jss={jssinstance}>
            <App />
        </JssProvider>
    </MuiStylesProvider>
);
