import { includes, trimStart, values } from 'lodash';

import UkJsonStub from '../../data/uk_json_stub.json';
import EnJsonStub from '../../data/en_json_stub.json';

const locales = { en: 'en', uk: 'uk' };

const getNormalizedPath = () => trimStart(window.location.pathname, '/');
export const setLocale = (locale) => (window.location.href = `${window.location.origin}/${locale}`);
export const setDefaultLocale = () => setLocale(locales.en);
export const isLocalePath = () => includes(values(locales), getNormalizedPath());
export const getLocale = () => (isLocalePath() ? getNormalizedPath() : locales.en);
export const getLocaleStub = () => {
    switch (getLocale()) {
        default:
        case locales.uk:
            return UkJsonStub;
        case locales.en:
            return EnJsonStub;
    }
};
