import Select from 'react-select';
import { find } from 'lodash';
import { useLang } from '../../../hooks/use_lang';

const options = [
    {
        value: 'en',
        label: (
            <img
                src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                alt="The United Kingdom flag"
                width={50}
                height={30}
            />
        )
    },
    {
        value: 'uk',
        label: (
            <img
                src="https://www.countryflags.com/wp-content/uploads/ukraine-flag-png-large.png"
                alt="The Ukrainian flag"
                width={50}
                height={30}
            />
        )
    }
];

const LanguageSelect = () => {
    const [lang, setLang] = useLang();

    return (
        <Select
            defaultValue={find(options, ['value', lang])}
            options={options}
            className="w-9"
            styles={{ indicatorsContainer: () => ({ display: 'none' }) }}
            onChange={({ value }) => setLang(value)}
        />
    );
};

export default LanguageSelect;
